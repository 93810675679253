import { LOCAL_IMPORT_MAP } from '../system-imports/_local-import-map';

type ImportMap = {
  imports: Record<string, string>;
};

const { SYSTEM_IMPORT_FILE_NAME, isLocal, devRemotes = '', REMOTE_IMPORT_HOST, REACT_APP_BASE_HREF } = process.env;
const importMapUrl = `${REACT_APP_BASE_HREF}/${SYSTEM_IMPORT_FILE_NAME}`;

async function insertImportMapWithUrl() {
  const script = document.createElement('script');
  script.id = 'tt-import-map-override-prod';
  script.type = 'systemjs-importmap';

  const importMap = await fetchImportMap();
  script.text = JSON.stringify(importMap);

  const overrideMapElement = document.querySelector('[data-is-importmap-override]');
  if (overrideMapElement) {
    overrideMapElement?.parentNode?.insertBefore(script, overrideMapElement);
  } else {
    document.head.appendChild(script);
  }

  (window as any).System.prepareImport(true);
}

async function insertImportMapDevLocal() {
  const script = document.createElement('script');
  script.id = 'tt-import-map-override-dev';
  script.type = 'systemjs-importmap';

  const importMap = await replaceLocalImportMapWithProd();
  script.text = JSON.stringify(importMap);

  const overrideMapElement = document.querySelector('[data-is-importmap-override]');

  if (overrideMapElement) {
    overrideMapElement?.parentNode?.insertBefore(script, overrideMapElement);
  } else {
    document.head.appendChild(script);
  }

  (window as any).System.prepareImport(true);
}

async function fetchImportMap(remoteHost = ''): Promise<ImportMap> {
  const response = await fetch(importMapUrl);
  let importMap: ImportMap = await response.json();
  importMap = {
    imports: Object.keys(importMap.imports).reduce((result, key) => {
      return {
        ...result,
        [key]: `${remoteHost}${REACT_APP_BASE_HREF}${importMap.imports[key]}`,
      };
    }, {}),
  };
  return importMap;
}

async function replaceLocalImportMapWithProd(): Promise<ImportMap> {
  const remoteModules = devRemotes.replace(/\//g, '-').replace(/\s/g, '').split(',');

  const localImports: ImportMap = remoteModules.reduce(
    (result, moduleName) => {
      const moduleNameWithoutVersion = removeModuleNameVersion(moduleName);

      return {
        imports: {
          ...result.imports,
          [`@totoplay/${moduleNameWithoutVersion}`]: LOCAL_IMPORT_MAP[moduleNameWithoutVersion],
        },
      };
    },
    {
      imports: {},
    } as ImportMap,
  );

  const remoteImports = await fetchImportMap(REMOTE_IMPORT_HOST);

  return {
    imports: {
      ...remoteImports.imports,
      ...localImports.imports,
    },
  };
}

function removeModuleNameVersion(moduleName: string) {
  return moduleName.replace(/(-v[0-9]+)$/g, '');
}

export async function checkAndLoadImportMapsByApp() {
  if (!importMapUrl) throw new Error('SYSTEM_IMPORT_FILE_NAME is not defined');

  if (isLocal) {
    await insertImportMapDevLocal();
  } else {
    await insertImportMapWithUrl();
  }
}
